import React from "react";
import twitter from "../navigation/assets/twitter.png";
import "./member.scss";

export default function Member(props) {
  const igConcat = ""
  return (
    <div className="a-box">
      <div className="img-container">
        <div className="img-inner">
          <div className="inner-skew">
            <img src={props.data.image} alt={props.data.position + ' Image'}/>
          </div>
        </div>
      </div>
      <div className="text-container">
        <img className="team-name-image" src={props.data.name} alt={props.data.position} />
        <span>{props.data.text}</span>
        {props.data.br && <br></br>}
        <span className="position">{props.data.position}</span>
        <a href={props.data.twitterUrl} > <img className="socials-ig" src={twitter} alt="ig-profile" width={50} /></a>
      </div>
    </div>
  );
}
