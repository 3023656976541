import { createContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import ApeContractData from "../blockchain/ApeContract";
import Swal from "sweetalert2";
import WalletConnectProvider from "@walletconnect/web3-provider";

export const BlockchainContext = createContext();
const { ethereum } = window;

let ethereum1 = ethereum;

const getProvider = () => {
  console.log("ethereum", ethereum1);
  return new ethers.providers.Web3Provider(ethereum1);
};

const getSigner = () => {
  const provider = getProvider();
  return provider.getSigner();
};

// returns promise
const getSignerAddress = () => {
  const provider = getProvider();
  return provider.getSigner().getAddress();
};

const getCurrentNetwork = () => {
  const provider = getProvider();
  return provider.getNetwork();
};

// returns Promise
const getNetworkChainId = async () => {
  const network = await getCurrentNetwork();
  return network.chainId;
};

export const BlockchainContextProvider = (props) => {
  const [currentSigner, setCurrentSigner] = useState("");
  const [currentSignerAddress, setCurrentSignerAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState();
  const [phase, setPhase] = useState();
  const [counter, setCounter] = useState();
  const [currentMaxLimit, setCurrentMaxLimit] = useState();
  const [priceIncrement, setPriceIncrement] = useState();
  const [error1, setError] = useState("");
  const [cost, setCost] = useState();
  const [totalSupply, setTotalSupply] = useState();
  const [provider, setProvider] = useState();

  useEffect(() => {
    //   checkIfWalletIsConnected();
    listenMMAccount(); // Event is registered in background
  }, []);

  /*  useEffect((provider, currentSignerAddress) => {
    console.log(provider, currentSignerAddress);
    getInfo(provider, currentSignerAddress);
  });
*/
  async function listenMMAccount() {
    try {
      ethereum1.on("accountsChanged", async function () {
        //  window.location.reload();
      });

      ethereum1.on("chainChanged", (currentChainId) => {
        window.location.reload();
      });
    } catch (error) {
      console.log(error);
    }
  }

  const checkIfWalletIsConnected = async () => {
    try {
      if (!ethereum) {
        //   Wallet Connect
        // Setup WalletConnect Providers
        const provider = new WalletConnectProvider({
          rpc: {
            /* 4: "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161", */
            1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
          },
        });

        await provider.enable();

        //  Create Web3 instance
        const web3Provider = new ethers.providers.Web3Provider(provider);
        setProvider(web3Provider);

        // Check Network
        const chainId = await (await web3Provider.getNetwork()).chainId;

        //  Enable session (triggers QR Code modal)

        if (chainId !== 1) {
          alert("Please Change Network to Ethereum !");
          return;
        }

        // Set Current Signer

        setCurrentSigner(web3Provider.getSigner());

        // Set Current Signer Address
        const signerAddress = await web3Provider.getSigner().getAddress();

        await getInfo(signerAddress, web3Provider);

        setCurrentSignerAddress(signerAddress);
      } else {
        const accounts = await ethereum.request({ method: "eth_accounts" });

        // Check Network
        const chainId = await getNetworkChainId();
        if (chainId !== 1) {
          alert("Please Change Network to Ethereum !");
          return;
        }

        if (accounts.length) {
          // SetProvider
          const provider = new ethers.providers.Web3Provider(ethereum);
          setProvider(provider);

          // Set Current Signer
          const signer = getSigner();
          setCurrentSigner(signer);

          // Set Current Signer Address
          const signerAddress = await getSignerAddress();

          await getInfo(signerAddress, provider);

          setCurrentSignerAddress(signerAddress);
        } else {
          console.log("No accounts found");
        }
      }
    } catch (error) {
      alert(error.data.message);

      throw new Error("No Ethereum Object");
    }
  };

  const connectWallet = async (number) => {
  

    try {
      if (number === 3) {
        //   Wallet Connect
        // Setup WalletConnect Providers
        const provider = new WalletConnectProvider({
          rpc: {
            /* 4: "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161", */
            1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
          },
        });

        await provider.enable();

        //  Create Web3 instance
        const web3Provider = new ethers.providers.Web3Provider(provider);
        setProvider(web3Provider);

        // Check Network
        const chainId = await (await web3Provider.getNetwork()).chainId;

        //  Enable session (triggers QR Code modal)

        if (chainId !== 1) {
          alert("Please Change Network to Ethereum !");
          return;
        }

        // Set Current Signer

        setCurrentSigner(web3Provider.getSigner());

        // Set Current Signer Address
        const signerAddress = await web3Provider.getSigner().getAddress();

        await getInfo(signerAddress, web3Provider);

        setCurrentSignerAddress(signerAddress);
        return "connected";
      } else if (number === 2) {
        if (!ethereum) {
          alert("Please Install Coinbase Wallet");
          return 0;
        } else {
          if (!window.ethereum.isMetaMask) {
            if (!window.ethereum.isCoinbaseWallet) {
              alert("Please Install Coinbase Wallet");
              return 0;
            } else {
              await ethereum.request({ method: "eth_requestAccounts" });

              // Check Network
              const chainId = await getNetworkChainId();

              //  Enable session (triggers QR Code modal)

              if (chainId !== 1) {
                alert("Please Change Network to Ethereum !");
                return;
              }

              // SetProvider
              const provider = new ethers.providers.Web3Provider(ethereum);
            
              setProvider(provider);

              // Set Current Signer
              const signer = getSigner();
              setCurrentSigner(signer);

              // Set Current Signer Address

              const signerAddress = await getSignerAddress();

              await getInfo(signerAddress, provider);

              setCurrentSignerAddress(signerAddress);
              return "connected";
            }
          } else if (window.ethereum.isMetaMask) {
            if (
              !window.ethereum.coinbaseWalletInstalls &&
              window.ethereum.overrideIsMetaMask !== true
            ) {
              alert("Please Install Coinbase Wallet");
              return 0;
            } else {
              let Provider;
            
              Provider = ethereum.providers.find(
                (provider) => provider.isCoinbaseWallet
              );
             

              ethereum1 = Provider;
              

              await Provider.request({ method: "eth_requestAccounts" });

              // Check Network
              const chainId = await getNetworkChainId();

              //  Enable session (triggers QR Code modal)

              if (chainId !== 1) {
                alert("Please Change Network to Ethereum !");
                return;
              }

              // SetProvider
              const provider = new ethers.providers.Web3Provider(Provider);
             
              setProvider(provider);

              // Set Current Signer
              const signer = getSigner();
              setCurrentSigner(signer);

              // Set Current Signer Address

              const signerAddress = await getSignerAddress();

              await getInfo(signerAddress, provider);

              setCurrentSignerAddress(signerAddress);
              return "connected";
            }
          }
        }
      } else if (number === 1) {
        if (!ethereum) {
          alert("Please Install Metamask");
          return 0;
        } else {
          if (!window.ethereum.isMetaMask) {
            alert("Please Install Metamask");
            return 0;
          } else {
            if (
              !window.ethereum.coinbaseWalletInstalls &&
              window.ethereum.overrideIsMetaMask !== true
            ) {
             
              await ethereum.request({ method: "eth_requestAccounts" });

              // Check Network
              const chainId = await getNetworkChainId();

              //  Enable session (triggers QR Code modal)

              if (chainId !== 1) {
                alert("Please Change Network to Ethereum !");
                return;
              }

              // SetProvider
              const provider = new ethers.providers.Web3Provider(ethereum);
            
              setProvider(provider);

              // Set Current Signer
              const signer = getSigner();
              setCurrentSigner(signer);

              // Set Current Signer Address

              const signerAddress = await getSignerAddress();

              await getInfo(signerAddress, provider);

              setCurrentSignerAddress(signerAddress);
              return "connected";
            } else {
             
              let Provider;
             

              Provider = ethereum.providers.find(
                (provider) => provider.isMetaMask
              );
              

              ethereum1 = Provider;
             

              await Provider.request({ method: "eth_requestAccounts" });

              // Check Network
              const chainId = await getNetworkChainId();

              //  Enable session (triggers QR Code modal)

              if (chainId !== 1) {
                alert("Please Change Network to Ethereum !");
                return;
              }

              // SetProvider
              const provider = new ethers.providers.Web3Provider(Provider);
             
              setProvider(provider);

              // Set Current Signer
              const signer = getSigner();
              setCurrentSigner(signer);

              // Set Current Signer Address

              const signerAddress = await getSignerAddress();

              await getInfo(signerAddress, provider);

              setCurrentSignerAddress(signerAddress);
              return "connected";
            }
          }
        }
      }
    } catch (error) {
      let errormsg = show_error_alert(error);
      return errormsg;

      throw new Error("No Ethereum Object");
    }
  };

  // Get APE Contract

  const getApeContract = async () => {
    console.log(currentSigner);
    if (!currentSigner) {
      console.log("Wallet not connected");
      // setIsLoading(false);
      return;
    }

    const ApeContract = new ethers.Contract(
      ApeContractData.address,
      ApeContractData.abi,
      provider
    );
    return ApeContract;
  };

  /* 
  * -------------------------------------------
            Functions
  * -------------------------------------------
  */

  const mint = async (_mintAmount) => {
    // Get Ape Contract
    const apeContract = await getApeContract();

    try {
      // Set Stake
      let total = counter + parseFloat(_mintAmount);

      if (total > currentMaxLimit) {
        let a = parseFloat(cost);
        let b = parseFloat(priceIncrement);
        let c = a + b;
        let newCost = total - currentMaxLimit;
        let oldCost = _mintAmount - newCost;
        newCost = c * newCost;

        oldCost = cost * oldCost;
        let value = oldCost + newCost;
        value = parseFloat(value).toFixed(3);
        const options = {
          value: ethers.utils.parseEther(value.toString()),
        };
        let tx = await apeContract
          .connect(currentSigner)
          .mint(_mintAmount, options);
        await tx.wait();
      } else {
        let value = cost * _mintAmount;

        value = parseFloat(value).toFixed(3);
        const options = {
          value: ethers.utils.parseEther(value.toString()),
        };
        let tx = await apeContract
          .connect(currentSigner)
          .mint(_mintAmount, options);
        await tx.wait();
      }
      await getInfo(currentSignerAddress, provider);
      let msg = "Minting Successful";
      return msg;
    } catch (error) {
      let errormsg = show_error_alert(error);
      return errormsg;
    }
  };

  async function show_error_alert(error) {
    let temp_error = error.message.toString();
    console.log(temp_error);
    let error_list = [
      "You have not staked any NFTs",
      "Sent Amount Wrong",
      "No balance to claim",
      "No NFTs selected to stake",
      "No NFTs selected to unstake",
      "Max Supply Reached",
      "You have already Claimed Free Nft.",
      "Presale have not started yet.",
      "You are not in Presale List",
      "Staking Period is still not over",
      "Presale Ended.",
      "You are not Whitelisted.",
      "Sent Amount Not Enough",
      "Exceeds max tx per address",
      "Max 20 Allowed.",
      "insufficient funds",
      "Sale is Paused.",
      "mint at least one token",
      "Exceeds max tx per address",
      "Not enough tokens left",
      "incorrect ether amount",
      "The contract is paused!",
      "5 tokens per wallet allowed in presale",
      "10 tokens per wallet allowed in publicsale",
      "Invalid merkle proof",
      "Not enough tokens allowed in current phase",
      "Sold out!!!",
      "No more tokens left in current phase",
      "Wallet limit Reached",
      "Exceeds max NFT per wallet",
      "Exceeds max NFT allowed per Wallet",
      "You are not whitelisted",
      "Exceeds max NFTs per wallet.",
      "Exceeds max NFT allowed per Wallet",
      "Exceeds max NFT per Wallet",
      "Insufficient funds!",
      "Whitelist minting is Paused!",
      "Only 1022 NFTs are available for Presale",
      "MetaMask Tx Signature: User denied transaction signature.",
      "User rejected the request.",
      "User denied account authorization",
      "User closed modal",
      "Request of type 'wallet_requestPermissions' already pending for origin ",
      "Already processing eth_requestAccounts. Please wait.",
    ];

    for (let i = 0; i < error_list.length; i++) {
      if (temp_error.includes(error_list[i])) {
        // set ("Transcation Failed")
        // alert(error_list[i]);
        console.log(error_list[i]);
        setError(error_list[i]);
        return error_list[i];
      }
    }
  }

  const getTotalSupply = async (provider) => {
    // Get Ape Contract

    const apeContract = new ethers.Contract(
      ApeContractData.address,
      ApeContractData.abi,
      provider
    );

    try {
      // Set Stake

      let _totalSupply = await apeContract.totalSupply();

      setTotalSupply(_totalSupply);
    } catch (error) {
      console.log(error);
    }
  };

  const getInfo = async (signerAddress, provider) => {
    // Get Ape Contract

    const apeContract = new ethers.Contract(
      ApeContractData.address,
      ApeContractData.abi,
      provider
    );

    try {
      // Set Stake

      let _cost;

      _cost = await apeContract.cost();

      _cost = ethers.utils.formatEther(_cost);

      setCost(_cost);

      let publicSalePaused = await apeContract.paused();
      if (publicSalePaused === true) {
        setPhase(1); //1 for sale not started
      } else {
        setPhase(2); // 2 for publicsale
      }

      let _limit = await apeContract.NFTPerAddress(signerAddress);

      setLimit(_limit);

      let _priceFloorCounter = await apeContract.priceFloorCounter();
      setCounter(parseFloat(_priceFloorCounter));
      let _currentMaxLimit = await apeContract.currentMaxLimit();
      setCurrentMaxLimit(parseFloat(_currentMaxLimit));
      let _priceIncrement = await apeContract.priceIncrement();
      _priceIncrement = ethers.utils.formatEther(_priceIncrement);
      setPriceIncrement(_priceIncrement);

      let _totalSupply = await apeContract.totalSupply();

      setTotalSupply(parseFloat(_totalSupply));
    } catch (error) {
      console.log(error);
    }
  };
  const setErrorMessage = async (msg) => {
    setError(msg);
  };

  const getSalePhase = async (provider) => {
    // Get Ape Contract

    const apeContract = new ethers.Contract(
      ApeContractData.address,
      ApeContractData.abi,
      provider
    );

    try {
      let publicSalePaused = await apeContract.paused();
      if (publicSalePaused === true) {
        setPhase(1); //1 for sale not started
      } else {
        setPhase(2); // 2 for publicsale
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getLimit = async (signerAddress, provider) => {
    // Get Ape Contract

    const apeContract = new ethers.Contract(
      ApeContractData.address,
      ApeContractData.abi,
      provider
    );

    try {
      let _limit = await apeContract.NFTPerAddress(signerAddress);

      setLimit(_limit);
    } catch (error) {
      console.log(error);
    }
  };
  const getCost = async (provider) => {
    // Get Ape Contract

    const apeContract = new ethers.Contract(
      ApeContractData.address,
      ApeContractData.abi,
      provider
    );

    try {
      let _cost;

      _cost = await apeContract.cost();

      _cost = ethers.utils.formatEther(_cost);

      setCost(_cost);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <BlockchainContext.Provider
      value={{
        currentSigner,
        currentSignerAddress,
        connectWallet,
        mint,
        error1,
        setErrorMessage,
        cost,
        totalSupply,
        counter,
        currentMaxLimit,
        priceIncrement,
        phase,
        limit,
      }}
    >
      {props.children}
    </BlockchainContext.Provider>
  );
};
