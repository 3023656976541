import react, { useEffect } from "react";
import Cross from "../../pages/assets/cross.png";
import "./popup.scss";

import MetaMask from "./assets/metamask.png";
import WallectConnect from "./assets/walletConnect.png";
import TrustWallet from "./assets/trustwallet.png";
import CoinbaseWallet from "./assets/coinbasewallet.png";
import { useContext } from "react";
import { BlockchainContext } from "../../context/BlockchainContext";

export default function Popup({ togglePopup }) {
  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = "hidden";

    return () => {
      body.style.overflow = "auto";
    };
  }, []);

  const { connectWallet, currentSignerAddress } = useContext(BlockchainContext);
  const connectHandler = async (number) => {
    // setFeedback("Connecting....");

    const tx = await connectWallet(number);
    console.log("txxxxxx", tx);
    if (tx === "connected") {
      togglePopup();
    } else if (tx === 0) {
      togglePopup();
    } else if (
      tx === "User rejected the request." ||
      "User denied account authorization" ||
      "User closed modal"
    ) {
      alert("Error: " + tx);
      togglePopup();
    } else {
      // setFeedback("");
      togglePopup();
    }
  };

  const handleConnectWallet = (type) => {
    /* Use this fc to handle connect and close the popup please */
    togglePopup();
  };

  const isDesktop = window.innerWidth > 960;

  return (
    <div className="popup-box">
      <div className={isDesktop ? "box" : "box-mobile"}>
        <div className="nav-box">
          <img onClick={togglePopup} src={Cross} className="popup-exit" alt="Close" />
        </div>
        {isDesktop ? (
          <>
            <div
              onClick={() => connectHandler(1)}
              className="grid-1 grid-style"
            >
              <div className="connect-type">
                <img src={MetaMask} className="wallet-image" alt="MetaMask" />
                <h2 className="connect-type-name">MetaMask</h2>
                <p className="connect-type-text">
                  Connect to your MetaMask Wallet
                </p>
              </div>
            </div>
            <div
              onClick={() => connectHandler(2)}
              className="grid-2 grid-style"
            >
              <div className="connect-type">
                <img src={CoinbaseWallet} className="wallet-image" alt="CoinBase Wallet" />
                <h2 className="connect-type-name">Coinbase Wallet</h2>
                <p className="connect-type-text">
                  Connect to your Coinbase Wallet
                </p>
              </div>
            </div>
            <div
              onClick={() => connectHandler(3)}
              className="grid-4 grid-style"
            >
              <div className="connect-type">
                <img src={WallectConnect} className="wallet-image" alt="WalletConnect" />
                <h2 className="connect-type-name">Wallet connect</h2>
                <p className="connect-type-text">
                  Connect to your custom wallet
                </p>
              </div>
            </div>
          </>
        ) : (
          <div
            onClick={() => connectHandler(3)}
            className="grid-all grid-style"
          >
            <div className="connect-type">
              <img src={WallectConnect} className="wallet-image" alt="WalletConnect" />
              <h2 className="connect-type-name">Wallet connect</h2>
              <p className="connect-type-text">Connect to your custom wallet</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
