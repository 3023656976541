import Icodis from "./icodis.png";
import Sheby from "./sheby.png";
import Souty from "./souty.png";
import Wihalr from "./wihalr.png";

import ShebyNFT from "./shebyNFT.png";
import SoutyNFT from "./soutyNFT.png";
import WihalrNFT from "./wihalrNFT.png";
import IcodisNFT from "./icodisNFT.png";

export const members = [
  {
    id: 0,
    name: Sheby,
    text: "Motorcycle lover, catches trends like Pokemons",
    image: ShebyNFT,
    position: "CO-FOUNDER & CEO",
    br: true,
    twitterUrl: "https://twitter.com/Sheby_CyberInk",
  },
  {
    id: 1,

    name: Souty,
    text: "Gaming freak, sleep deprivation is his friend",
    image: SoutyNFT,
    br: true,
    position: "WEB DEVELOPMENT",
    twitterUrl: "https://twitter.com/Souty_CyberInk",
  },
  {
    id: 2,

    name: Wihalr,
    text: 'Nutrition guru, binds the community together "firmer" than marriage',
    image: WihalrNFT,
    position: "MARKETING & CS",
    twitterUrl: "https://twitter.com/wihalr_CyberInk",
  },
  {
    id: 3,

    name: Icodis,
    text: "Problem solver, artificial intelligence is no match for natural stupidity",
    image: IcodisNFT,
    position: "SC DEVELOPMENT",
    twitterUrl: "https://twitter.com/Icodis_CyberInk",
  },
];

export default {
  members,
};
