import { useEffect } from "react";
import "./roadmap1.scss";

export default function Roadmap() {
  return (
    <>
      <div className="timeline">
        <div className="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
          <div className="timeline__event__content ">
            <div className="timeline__event__title">RARITY TOOLS LISTING</div>
            <div className="timeline__event__description">
              <p>
                After more than six months of work, this is the first milestone
                that will show us your interest and guarantee inclusion in the
                rarity.tools ranking.
              </p>
            </div>
            <span className="percent__roadmap">10%</span>
          </div>
        </div>

        <div className="timeline__event animated fadeInUp delay-1s timeline__event--type1">
          <div className="timeline__event__content">
            <div className="timeline__event__title">MERCH & STORE LAUNCH</div>
            <div className="timeline__event__description">
              <p>
                Once we reach this milestone, we plan to expand our product
                portfolio and start working on launching an e-shop with our
                merchandise.
              </p>
            </div>
            <span className="percent__roadmap">30%</span>
          </div>
        </div>

        <div className="timeline__event animated fadeInUp delay-1s timeline__event--type1">
          <div className="timeline__event__content">
            <div className="timeline__event__title">
              SPECIAL, THEMED AND UNIQUE SURPRISE GIFTS
            </div>
            <div className="timeline__event__description">
              <p>
                What a surprise it would be if we revealed everything in
                advance.
              </p>
            </div>
            <span className="percent__roadmap">50%</span>
          </div>
        </div>

        <div className="timeline__event animated fadeInUp timeline__event--type1">
          <div className="timeline__event__content">
            <div className="timeline__event__title">
              UPCOMING PROJECT REVEAL
            </div>
            <div className="timeline__event__description">
              <p>
                As soon as we meet this mintage milestone, we will release
                initial information about our next project.
              </p>
            </div>
            <span className="percent__roadmap">70%</span>
          </div>
        </div>

        <div className="timeline__event animated fadeInUp timeline__event--type1">
          <div className="timeline__event__content">
            <div className="timeline__event__title">METAVERSE</div>
            <div className="timeline__event__description">
              <p>
                At this milestone we purchase a plot of land in The Sandbox
                metaverse.
              </p>
            </div>
            <span className="percent__roadmap">80%</span>
          </div>
        </div>

        <div className="timeline__event animated fadeInUp timeline__event--type1">
          <div className="timeline__event__content">
            <div className="timeline__event__title">TATTOO GIVEAWAY</div>
            <div className="timeline__event__description">
              <p>
                A giveaway of 3 custom tattoo vouchers from @michal_hladik_art
                for Cyber Viking Warriors holders.
              </p>
            </div>
            <span className="percent__roadmap">90%</span>
          </div>
        </div>

        <div className="timeline__event animated fadeInUp timeline__event--type1">
          <div className="timeline__event__content">
            <div className="timeline__event__title">VIKING THEMED VACATION</div>
            <div className="timeline__event__description">
              <p>
                A randomly selected NFT holder and their companion will receive
                a week-long Viking-themed vacation.
              </p>
            </div>
            <span className="percent__roadmap">100%</span>
          </div>
        </div>
      </div>
    </>
  );
}
