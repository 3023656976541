import styled from "styled-components";
import Viking from "./assets/viking-resized-min.png";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Roboto";
  font-weight: bold;
`;

export const FaqContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: block;
  font-family: "Roboto";
`;

export const VideoPromo = styled.iframe`
 width: 65%;
 height: 350px;
 @media only screen and (max-width: 960px) {
  width: 60%;
  height: 350px;
}
@media only screen and (max-width: 600px) {
  width: 70%;
  height: 350px;
}
@media only screen and (max-width: 470px) {
  width: 90%;
  height: 350px;
}
`;

export const RoadmapContainer = styled.div`
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  font-family: "Roboto";
  text-align: center;
  margin: auto 0;
`;

export const Team = styled.div`
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  font-family: "Roboto";
  text-align: center;
  margin: auto 0;
`;

export const AboutDiv = styled.div`
  margin-top: 5%;
  width: 100%;
  height: 100vh;
  display: flex;
  height: 130vh;
  justify-content: flex-start;
  flex-direction: column;
  font-family: "Roboto";
  font-weight: bold;
  @media only screen and (max-width: 1300px) {

  }
  @media only screen and (max-width: 800px) {
    height: 150vh;
  }
  @media only screen and (max-width: 600px) {
    height: 200vh;
  }
  @media only screen and (max-width: 500px) {
    height: 230vh;
  }
  @media only screen and (max-width: 400px) {
    height: 320vh;
  }
`;

export const Header = styled.span`
  @import url("https://fonts.googleapis.com/css2?family=Rock+Salt&family=Rubik&display=swap");
  color: white;
  font-size: 6.5em;
  display: block;
  position: relative;
  z-index: 2;
  padding: 0;
  margin: 0;
  font-family: "Rock Salt", sans-serif;
  @media only screen and (max-height: 960px) {
    top: 65px;
  }

  @media only screen and (max-heigt: 960px) {
    font-size: 5.5em;
  }
  @media only screen and (max-width: 560px) {
    font-size: 3.5em;
  }
  @media only screen and (max-width: 390px) {
    font-size: 3.5em;
  }
`;

export const Text = styled.span`
  @import url("https://fonts.googleapis.com/css2?family=Rock+Salt&family=Rubik&display=swap");
  font-size: 2em;
  color: white;
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 2;
  font-family: "Rock Salt", sans-serif;
  @media only screen and (max-width: 960px) {
  }
  @media only screen and (max-width: 560px) {
  }
  @media only screen and (max-width: 390px) {
  }
`;

export const VikingPng = styled.img`
  position: relative;
  font-size: 3em;
  color: white;
  width: 640px;
  height: 620px;
  top: 105px;
  animation: fade 1s ease-in both;
  opacity: 0;
  @media only screen and (max-width: 960px) {
    top: 185px;
    font-size: 2em;
    width: 620px;
    height: 600px;
  }
  @media only screen and (max-width: 560px) {
    font-size: 2em;
    width: 490px;
    height: 470px;
  }
  @media only screen and (max-width: 390px) {
    top: 90px;
    font-size: 2em;
    width: 390px;
    height: 370px;
  }

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

VikingPng.defaultProps = {
  src: Viking,
  alt: 'CyberViking CyberInk',
};



export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 13%;
  z-index: 2;
`;

export const OpenseaContainer = styled.div`
  z-index: 2;
`;

export const OpenseaImage = styled.img`
  border-radius: 50%;
  box-shadow: white 0px 0px 15px 0px;
  backdrop-filter: blur(100px) contrast(70%);
  -webkit-backdrop-filter: blur(100px) contrast(70%);
  width: 50px;
  height: 50px;
  &:hover {
    box-shadow: white 0 0px 25px 0px;
  }
`;

export const BlockFaq = styled.div`
  width: 50%;
  height: auto;
  margin: 1%;
  margin: 0 auto;
  padding-bottom: 10%;
  @media only screen and (max-width: 1600px) {
    width: 60%;
  }
  @media only screen and (max-width: 1200px) {
    width: 70%;
  }
  @media only screen and (max-width: 860px) {
    width: 85%;

  }
`;
