import { scroller } from "react-scroll";

export const scrollToSection = (
  selector = "",
  faq = "",
  center = false,
  remove = false
) => {
  if (center) {
    if (faq) {
      document.getElementById(faq).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      var element = document.getElementById(faq);
      if (element) {
        element.setAttribute("open", true);
      } else {
        document.getElementById(selector).scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }

      if (remove) {
        const faqHTMLcolletion = document.getElementsByClassName("faqs");
        var faqElements = Array.from(faqHTMLcolletion);
        faqElements.map((item) => item.removeAttribute("open"));
      }
    }
  } else {
    scroller.scrollTo(selector, {
      duration: 1200,
      delay: 100,
      smooth: "easeInOutQuart",
    });
  }
};
