import React from "react";
import "./footer.scss";
import logo from "./../navigation/assets/logo_white.png";
import ig from "./instagram.png";
import discord from "./discord.png";
import twitter from "./twitter.png";
import opensea from "./opensea.png";
import { scrollToSection } from "./../../helpers/scrollTo";

export default function Footer() {
  const handleNavigationClick = (selector) => {
    scrollToSection(selector);
  };

  return (
    <div className="footer">
      <div className="inner-footer">
        <div className="footer-items left">
           <h3>Verified smart contract address: </h3>
          <a style={{textDecoration: 'none', color: 'white', cursor: 'pointer', wordWrap: 'break-word' }} href="https://etherscan.io/address/0x5212c7ad8b91c7bd21ee6779f150a2ba348a15ad">0x5212C7AD8b91C7bD21Ee6779f150A2bA348a15ad</a>
        </div>

        <div className="footer-items center">
          <img
            onClick={() => handleNavigationClick("home")}
            style={{ cursor: "pointer" }}
            src={logo}
            className="footer-logo"
            alt="CyberInk Logo"
          />
        </div>

        <div className="footer-items right">
          <h3>Contact us:</h3>
          <ul>
            <li>
              <i className="fa fa-envelope" aria-hidden="true"></i>
              support@cyberink.io
            </li>
            <div className="social-media">
              <a target="_blank" href="https://discord.gg/ZNycXk8pKa">
                <img
                  className="links-footer"
                  src={discord}
                  width={52}
                  alt="Discord"
                />
              </a>
              <a target="_blank" href="https://twitter.com/CyberInkNFT">
                <img
                  className="links-footer"
                  src={twitter}
                  width={52}
                  alt="Twitter"
                />
              </a>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
}
