import "./faq.scss";
import { scrollToSection } from "./../../helpers/scrollTo";

export default function Faq() {
  const handleSrollToClick = (selector, faq) =>
    scrollToSection(selector, faq, true, true);

  return (
    <>
      <div className="faq">
        <details className="faqs">
          <summary>What is CyberInk and what are future plans?</summary>
          <div className="faq__content">
            <p>
              Our vision is to create one of the largest NFT art galleries. We
              are in a close contact with many world renowned artists, each with
              their own unique style. We would like to bring their talent to the
              world of NFT and metaverse. Art can set you apart from others. It
              allows for more personal customisation of virtual properties or
              characters resulting in metaverse developers to integrate real
              art.
            </p>
          </div>
        </details>
        <details className="faqs">
          <summary>How much will the Cyber Viking Warrior mint cost?</summary>
          <div className="faq__content">
            <div>
              We appreciate early supporters, so the mint price raises by 0.02
              ETH after each 1,000 units sold.
              <p>1 - 1000 pcs = 0.04 ETH/piece</p>
              <p>1001 - 2000 pcs = 0.06 ETH/piece</p>
              <p>2001 - 3000 pcs = 0.08 ETH/piece</p>
              <p>3001 - 4000 pcs = 0.10 ETH/piece</p>
              <p>4001 - 5000 pcs = 0.12 ETH/piece</p>
              <p>5001 - 6000 pcs = 0.14 ETH/piece</p>
              <p>6000 - 6666 pcs = 0.16 ETH/piece</p>
            </div>
          </div>
        </details>
        <details className="faqs">
          <summary>
            I want to participate as an artist, what should I do?
          </summary>
          <div className="faq__content">
            <p>
              If you are interested in collaboration, please contact us via
              email (support@cyberink.io) or DM us on our discord server. We are
              also planning a competition for lesser known yet talented artists
              to help promote their skill through the NFT world.
            </p>
          </div>
        </details>

        <details className="faqs" id="Collab">
          <summary>With whom are you going to collaborate?</summary>
          <div className="faq__content">
            <p>
              We intend to expand our platform through partnerships with
              celebrities with arts, sports or music backgrounds. We are also in
              discussions with game development and metaverse companies. This is
              only the beginning.
            </p>
            <p className="back_to_utilities">
              <span
                onClick={() => handleSrollToClick(null, "utility-text", true)}
                className="back_to_utilities_text"
              >
                Back to Utilities <span className="up_sign_faq">↟</span>
              </span>
            </p>
          </div>
        </details>
        <details className="faqs" id="VIP">
          <summary>What are the benefits of becoming a VIP member?</summary>
          <div className="faq__content">
            <p>
              A limited number of VIP spots will be open in each collection. VIP
              members will have an early access pass to all future CyberInk
              project pre-sales, just before everyone else. They will receive
              special gifts, airdrops and have higher chance of winning in our
              competitions.
            </p>
            <p className="back_to_utilities">
              <span
                onClick={() => handleSrollToClick(null, "utility-text", true)}
                className="back_to_utilities_text"
              >
                Back to Utilities <span className="up_sign_faq">↟</span>
              </span>
            </p>
          </div>
        </details>
        <details className="faqs" id="Charity">
          <summary>Does CyberInk support any charities?</summary>
          <div className="faq__content">
            <p>
              Vikings always gave back to those in need. It will be a total of
              10% of all proceeds from the mintage that we will distribute to 5
              different organizations chosen and voted by our community. An
              equal portion of the donation will be sent for each 20% of the
              roadmap milestone.
            </p>
            <p className="back_to_utilities">
              <span
                onClick={() => handleSrollToClick(null, "utility-text", true)}
                className="back_to_utilities_text"
              >
                Back to Utilities <span className="up_sign_faq">↟</span>
              </span>
            </p>
          </div>
        </details>
        <details className="faqs" id="GA">
          <summary>How often will giveaways and airdrops happen?</summary>
          <div className="faq__content">
            <p>
              Planned giveaways and airdrops will take place together with
              charitable donations for every 20% reached in our mintage
              milestone roadmap. Spontaneous giveaways and airdrops will be
              announced on Discord or Twitter. All CyberInk NFT holders will be
              eligible.
            </p>
            <p className="back_to_utilities">
              <span
                onClick={() => handleSrollToClick(null, "utility-text", true)}
                className="back_to_utilities_text"
              >
                Back to Utilities <span className="up_sign_faq">↟</span>
              </span>
            </p>
          </div>
        </details>
        <details className="faqs" id="Merch">
          <summary>When will the merchandise be available?</summary>
          <div className="faq__content">
            <p>
              As soon as we reach the 30% milestone from our planned mintage
              roadmap, we will start working on launching an e-shop with our
              merchandise.
            </p>
            <p className="back_to_utilities">
              <span
                onClick={() => handleSrollToClick(null, "utility-text", true)}
                className="back_to_utilities_text"
              >
                Back to Utilities <span className="up_sign_faq">↟</span>
              </span>
            </p>
          </div>
        </details>
        <details className="faqs" id="Metaverse">
          <summary>What metaverse are you targeting?</summary>
          <div className="faq__content">
            <p>
              We believe that the concept of metaverse is at the very beginning.
              We would like to implement the use of our collections in every
              promising metaverse project as tattoos, textures for the game
              development industry, or even wall art in your virtual residences,
              etc. The first project we would like to participate in is The
              Sandbox.
            </p>
            <p className="back_to_utilities">
              <span
                onClick={() => handleSrollToClick(null, "utility-text", true)}
                className="back_to_utilities_text"
              >
                Back to Utilities <span className="up_sign_faq">↟</span>
              </span>
            </p>
          </div>
        </details>
      </div>
    </>
  );
}
