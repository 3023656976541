import { useState } from "react";
import gallery from "./assets/sliding_galery.png";
import "./mint.css";
import ButtonImageM from "./assets/mintButton_m.png";
import ButtonImage from "./assets/mintButton.png";
import { useContext, useEffect } from "react";
import { BlockchainContext } from "../../context/BlockchainContext";

/* (Souty) TODO: replace by UX img Buttons from Hladik, these are just example */
import ButtonConnectImage from "./assets/connect_button_example_wip.png";
import ButtonConnectMImage from "./assets/connect_m_example_wip.png";

import Popup from "../popup/Popup.js";

export default function Mint() {
  const [account, setAccount] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const isDesktop = window.innerWidth > 960;
  const [mintAmount, setMint] = useState(1);
  const [feedback, setFeedback] = useState("");
  const togglePopup = () => setIsOpen(!isOpen);
  const {
    error1,
    mint,
    cost,
    setErrorMessage,
    totalSupply,
    counter,
    currentMaxLimit,
    priceIncrement,
    phase,
    limit,
  } = useContext(BlockchainContext);
  const [mintCost, setMintCost] = useState();
  const mintHandler = async () => {
    if (phase === 1) {
      setFeedback("SALE HAS NOT STARTED YET");
    } else if (phase === 2) {
      setFeedback("Processing...");
      const tx = await mint(mintAmount);
      console.log("Minting  ", tx);
      if (tx !== "Minting Successful") {
        setFeedback("Minting Failed: " + tx);
      } else {
        setFeedback("Minting Successful");
      }
    }
  };

  const handleChange = (e) => {
    setMint(e.target.value);
  };

  const { connectWallet, currentSignerAddress } = useContext(BlockchainContext);


  useEffect(() => {
    let total = parseFloat(counter) + parseFloat(mintAmount);

    if (total > currentMaxLimit) {
      let a = parseFloat(cost);
      let b = parseFloat(priceIncrement);
      let c = a + b;

      let newCostNumber = total - currentMaxLimit;
      let oldCostnumber = mintAmount - newCostNumber;
      let newCost = c * newCostNumber;

      let _cost = cost * oldCostnumber;
      let totalCost = _cost + newCost;
      setMintCost(totalCost);
    } else {
      setMintCost(cost * mintAmount);
    }
  });

  return (
    <>
      {isOpen && <Popup togglePopup={togglePopup} />}
      <div className="container">
        <div className="row">
          <div className="flex-nft-mint">
            {currentSignerAddress.toString() !== "" ? (
              <>
                <div className="sliding-nft-container">
                  <div className="sliding-nft-promo-image" />
                </div>
                <select
                  className="nft-count-select"
                  name="nft-mint-count"
                  id="nft-count-select"
                  onChange={handleChange}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                </select>
                {totalSupply > 10 ? (
                  <div className="nft-info">
                    {/* This should be visible after some sellings (consult with Sheby) f.e. after 500 sold we will show this. */}
                    <span className="left-nft-info">
                      {/* Max supply here */}
                      {6666 - totalSupply} available
                    </span>
                    <span className="right-nft-info">
                      {" "}
                      {parseFloat(mintCost).toFixed(3)} ETH
                    </span>
                  </div>
                ) : (
                  <div className="nft-info">
                    {/* This should be visible after some sellings (consult with Sheby) f.e. after 500 sold we will show this. */}
                    <span className="left-nft-info"> </span>
                    <span className="right-nft-info">
                      {" "}
                      {parseFloat(mintCost).toFixed(3)} ETH
                    </span>
                  </div>
                )}
                <p>
                  {currentSignerAddress &&
                    `${currentSignerAddress.slice(
                      0,
                      6
                    )}...${currentSignerAddress.slice(
                      currentSignerAddress.length - 4,
                      currentSignerAddress.length
                    )}`}
                </p>
                <p>{feedback}</p>
                <img
                  onClick={() => mintHandler()}
                  src={isDesktop ? ButtonImage : ButtonImageM}
                  className="mint-button"
                  alt="CyberInk NFT Mint Button" 
                />
              </>
            ) : (
              <>
                <p>{feedback}</p>
                <img
                  onClick={() => togglePopup()}
                  src={isDesktop ? ButtonConnectImage : ButtonConnectMImage}
                  className="mint-button"
                  alt="CyberInk NFT Mint Button" 
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
