import React from "react";
import Loader from "../assets/loader_b.gif";
import Welcome2 from "../assets/loading_banner.png";
import "../styles.scss";

export default function LoadingScreen() {
  return (
    <>
      <div className="loading-screen">
        <img src={Welcome2} className="intro-header" alt="Welcome CyberInk NFT" />
        {/*         <img src={Loader} className="loader-header" /> */}{" "}
      </div>
    </>
  );
}

/* <div className="loading-screen">
        <img
          className="hammer-animation"
          src={HammerAnimation}
          width={1600}
          height={500}
        />
</div> */

/*   <div className="loading-screen">
        <img src={Welcome} className="intro-header" />
        <img
          className="hammer-animation"
          src={HammerAnimation}
          width={200}
          height={120}
        />
        <div>
          <img src={Dot} width={25} className="dot dot-1" />
          <img src={Dot} width={25} className="dot dot-2" />
          <img src={Dot} width={25} className="dot dot-3" />
        </div>
      </div> */
