import Member from "./Member";
import Members from "./Members";
import { Container } from "./styledMember.jsx";

export default function MemberContainer() {
  const items = Members.members;
  const listItems = items.map((item) => <Member key={item.id} data={item} />);

  return (<Container className="container-team">{listItems}</Container>);
}
