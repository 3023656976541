import React, { useState, useEffect } from "react";
import Navig from "../components/navigation/Nav";
/* import MMint from "../components/mint"; */
import Footer from "../components/footer/Footer";
import Faq from "../components/faq/Faq";
import Roadmap from "./Roadmap";
import Team from "./Team";
import FaqHeader from "./assets/faq.webp";
import RoadmapHeader from "./assets/roadmap.webp";
import UtilityHeader from "./assets/utility.webp";
import TeamHeader from "./assets/team.webp";
import Mint from "./assets/mint.webp";
import MintContainer from "../components/mint/mint.js";
import Cross from "./assets/cross.webp";
import ArtistImage from "./assets/michal_hladik_logo.webp";
import ArtistNameImg from "./assets/hladik_tattoo.webp";
import IgArtist from "./../components/navigation/assets/insta.png";
import {
  Swiper,
  SwiperSlide,
} from "../../node_modules/swiper/react/swiper-react";
import { Autoplay, Pagination, Navigation, FreeMode } from "swiper";
import { Controller, Scene } from "react-scrollmagic";

import vikingHead1 from "./assets/galery1.webp";
import vikingHead2 from "./assets/galery2.webp";
import vikingHead3 from "./assets/galery3.webp";
import vikingHead4 from "./assets/galery4.webp";
import vikingHead5 from "./assets/galery5.webp";
import vikingHead6 from "./assets/galery6.webp";
import vikingHead7 from "./assets/galery7.webp";
import vikingHead8 from "./assets/galery8.webp";
import vikingHead9 from "./assets/galery9.webp";
import vikingHead10 from "./assets/galery10.webp";

import toBeAnounced from "./assets/to_be_anounced_mint.webp";
import toBeAnouncedm from "./assets/to_be_anounced_mint_m.webp";

import openseaButton from "./assets/button_opensea.webp";
import discordButton from "./assets/button_discord.webp";
import twitterButton from "./assets/button_twitter.webp";

import "../../node_modules/swiper/swiper.scss";
import "../../node_modules/swiper/modules/pagination/pagination";
import "../../node_modules/swiper/modules/navigation/navigation";
import "../../node_modules/swiper/modules/free-mode/free-mode";

import "./../styles.scss";
import { scrollToSection } from "./../helpers/scrollTo";

import { Container, BlockFaq, VideoPromo } from "./styledHome";

export default function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [countImage, setCountImage] = useState(0);

  const isMintLive = true;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountImage((prevCountImage) =>
        prevCountImage === 4 ? 0 : prevCountImage + 1
      );
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleSrollToClick = (selector, faq) =>
    scrollToSection(selector, faq, true);

  const imagesGalery = {
    desktop: ["IMG_3601", "IMG_3602", "IMG_3603", "IMG_3604", "IMG_3605"],
    mobile: [
      "IMG_3601_M",
      "IMG_3602_M",
      "IMG_3601_M",
      "IMG_3603_M",
      "IMG_3601_M",
    ],
  };

  const image = `url(${
    require(`./assets/${imagesGalery.desktop[countImage]}.webp`).default
  })`;

  const imageMobile = `url(${
    require(`./assets/${imagesGalery.mobile[countImage]}.webp`).default
  })`;

  const isDesktop = window.innerWidth > 960;

  return (
    <React.Fragment>
      <Controller>
        <Navig togglePopup={togglePopup} />
        <h1 className="none">CyberInk</h1>
        <div id="home" className="home">
          <div className="container">
            <Container className="home-container">
              <h1 className="none">Cyber Vikings</h1>
              <div
                key={Math.random() + countImage}
                style={{
                  backgroundImage: isDesktop ? image : imageMobile,
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: isDesktop ? "contain" : "cover",
                  width: isDesktop ? "100%" : "90%",
                  height: isDesktop ? "130%" : "120%",
                  animation: "fade 1.5s ease-in both",
                }}
                id="image-promo"
                className="image-promo"
              >
                <div className="image-promo-viking"></div>
              </div>
            </Container>
          </div>
        </div>
        <div className="wrapper-section">
          <div className="utility clippy">
            <Scene classToggle={"reveal"} reverse={false} indicators={false}>
              <div className="animate">
                <h1 className="none">Utility CyberInk</h1>
                <img
                  className="utility-header section-header"
                  src={UtilityHeader}
                  alt="CyberInk Utility"
                />
                <div id="utility-text" className="utility-text">
                  <p className="utility-text-head">
                    Cyber Viking Warriors are 6,666 hand-drawn collectible NFTs
                    stored as ERC-721A low gas fee smart contract on the
                    Ethereum blockchain and hosted on the IPFS.
                  </p>
                  <p className="utility-text-head">
                    The Vikings have always been at their strongest as a unit
                    and together they have accomplished great feats. Each Cyber
                    Viking Warrior is unique and has 7 different traits selected
                    from 281 attributes. You can randomly mint a black and white
                    or color edition. The black and white editions will be used
                    primarily for merchandise design, where their holders will
                    be rewarded. The amount of blood on your warriors is a
                    simple indicator of rarity.
                  </p>
                  <ul className="ul-utility">
                    <li className="li-utility">
                      <span
                        onClick={() => handleSrollToClick(null, "Collab", true)}
                        style={{ lineHeight: "30px" }}
                      >
                        COLLABORATIONS
                        <span
                          style={{
                            position: "relative",
                            paddingLeft: "15px",
                            top: "4px",
                            color: "grey",
                            fontSize: "30px",
                            cursor: "pointer",
                          }}
                        >
                          &#8608;
                        </span>
                      </span>
                    </li>
                    <li className="li-utility">
                      <span
                        onClick={() => handleSrollToClick(null, "VIP", true)}
                        style={{ lineHeight: "30px" }}
                      >
                        VIP MEMBERS
                        <span
                          style={{
                            position: "relative",
                            paddingLeft: "15px",
                            top: "4px",
                            color: "grey",
                            fontSize: "30px",
                            cursor: "pointer",
                          }}
                        >
                          &#8608;
                        </span>
                      </span>
                    </li>
                    <li className="li-utility">
                      <span
                        onClick={() =>
                          handleSrollToClick(null, "Charity", true)
                        }
                        style={{ lineHeight: "30px" }}
                      >
                        CHARITY
                        <span
                          style={{
                            position: "relative",
                            paddingLeft: "15px",
                            top: "4px",
                            color: "grey",
                            fontSize: "30px",
                            cursor: "pointer",
                          }}
                        >
                          &#8608;
                        </span>
                      </span>
                    </li>
                    <li className="li-utility">
                      <span
                        onClick={() => handleSrollToClick(null, "GA", true)}
                        style={{ lineHeight: "30px" }}
                      >
                        AIRDROPS
                        <span
                          style={{
                            position: "relative",
                            paddingLeft: "15px",
                            top: "4px",
                            color: "grey",
                            fontSize: "30px",
                            cursor: "pointer",
                          }}
                        >
                          &#8608;
                        </span>
                      </span>
                    </li>
                    <li className="li-utility">
                      <span
                        onClick={() => handleSrollToClick(null, "GA", true)}
                        style={{ lineHeight: "30px" }}
                      >
                        GIVEAWAYS
                        <span
                          style={{
                            position: "relative",
                            paddingLeft: "15px",
                            top: "4px",
                            color: "grey",
                            fontSize: "30px",
                            cursor: "pointer",
                          }}
                        >
                          &#8608;
                        </span>
                      </span>
                    </li>
                    <li className="li-utility">
                      <span
                        onClick={() => handleSrollToClick(null, "Merch", true)}
                        style={{ lineHeight: "30px" }}
                      >
                        MERCHANDISE
                        <span
                          style={{
                            position: "relative",
                            paddingLeft: "15px",
                            top: "4px",
                            color: "grey",
                            fontSize: "30px",
                            cursor: "pointer",
                          }}
                        >
                          &#8608;
                        </span>
                      </span>{" "}
                    </li>
                    <li className="li-utility">
                      <span
                        onClick={() =>
                          handleSrollToClick(null, "Metaverse", true)
                        }
                        style={{ lineHeight: "30px" }}
                      >
                        METAVERSE
                        <span
                          style={{
                            position: "relative",
                            paddingLeft: "15px",
                            top: "4px",
                            color: "grey",
                            fontSize: "30px",
                            cursor: "pointer",
                          }}
                        >
                          &#8608;
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="video-container">
                  <VideoPromo
                    titile="CyberInk Promo Video"
                    src="https://www.youtube.com/embed/e-nAhY1bbAE"
                  ></VideoPromo>
                </div>
              </div>
            </Scene>
          </div>
        </div>
        {isMintLive ? (
          <div className="wrapper-section">
            <div className="mint-container">
              <MintContainer />
            </div>
          </div>
        ) : (
          <div className="wrapper-section">
            <div className="mint-container">
              <div className="container">
                <div className="row">
                  <div className="flex-nft-mint">
                    <img
                      onClick={() =>
                        alert(
                          "Oops! I see you can't wait! Mint will start soon! :)"
                        )
                      }
                      src={isDesktop ? toBeAnounced : toBeAnouncedm}
                      className="mint-button"
                      alt="Mint Button" 
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="wrapper-section">
          <div className="roadmap clippy2">
            <Scene classToggle={"reveal"} reverse={false} indicators={false}>
              <div className="animate">
                <h1 className="none">CyberInk Roadmap</h1>

                <img
                  className="roadmap-header section-header"
                  src={RoadmapHeader}
                  alt="CyberInk Roadmap"
                />
                <Roadmap />
              </div>
            </Scene>
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            alignItems: "center",
            paddingBottom: "15px",
            paddingTop: "45px",
          }}
        >
          <Scene classToggle={"reveal"} reverse={false} indicators={false}>
            <div className="animate">
              <Swiper
                spaceBetween={25}
                centeredSlides={true}
                slidesPerView={window.innerWidth > 1000 ? 3 : 1}
                freeMode={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                style={{ paddingTop: 40, width: "90%" }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation, FreeMode]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img
                    src={vikingHead1}
                    className="slider-images"
                    alt="CyberInk NFT"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={vikingHead2}
                    className="slider-images"
                    alt="CyberInk NFT"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={vikingHead3}
                    className="slider-images"
                    alt="CyberInk NFT"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={vikingHead4}
                    className="slider-images"
                    alt="CyberInk NFT"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={vikingHead5}
                    className="slider-images"
                    alt="CyberInk NFT"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={vikingHead6}
                    className="slider-images"
                    alt="CyberInk NFT"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={vikingHead7}
                    className="slider-images"
                    alt="CyberInk NFT"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={vikingHead8}
                    className="slider-images"
                    alt="CyberInk NFT"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={vikingHead9}
                    className="slider-images"
                    alt="CyberInk NFT"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={vikingHead10}
                    className="slider-images"
                    alt="CyberInk NFT"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </Scene>
        </div>
        <div className="wrapper-section">
          <div className="team-container clippy">
            <Scene classToggle={"reveal"} reverse={false} indicators={false}>
              <div className="animate">
                <h1 className="none">CyberInk Team</h1>
                <img
                  className="team-header section-header"
                  src={TeamHeader}
                  alt="CyberInk Team"
                />
                <Team />
                <div className="artist">
                  <div className="a-box-artist">
                    <div className="img-container-artist">
                      <img
                        className="artist-image"
                        src={ArtistImage}
                        alt="Michal Hladik Photo"
                      />
                      <img
                        className="artist-img-name"
                        src={ArtistNameImg}
                        alt="Michal Hladik"
                      />
                    </div>
                    <div className="text-container-artist">
                      <div
                        className="text-container-artist-header"
                        style={{ paddingBottom: "10px" }}
                      >
                        <h1 className="none">CyberInk Artists</h1>
                        <a
                          target="_blank"
                          href="https://www.instagram.com/michal_hladik_art/?hl=en"
                        >
                          <img
                            className="artist-icons"
                            src={IgArtist}
                            alt="Michal Hladik Instagram"
                          />
                        </a>
                        <span style={{ lineHeight: "35px" }}>
                          @michal_hladik_art
                        </span>
                        <span style={{ lineHeight: "35px" }}>
                          | Tattoo Artist
                        </span>
                      </div>
                      <div className="text-container-artist-paragraph">
                        <span style={{ display: "block" }}>
                          A well-known and respected among the world's
                          tattooists for his unique and pure style. Our pilot
                          collection is based on a theme very close to him and
                          us, i.e. Nordic mythology.
                        </span>
                      </div>
                      <p className="back_to_utilities">
                        <a
                          href="https://www.instagram.com/michal_hladik_art/"
                          className="back_to_utilities_text"
                          style={{ marginRight: "15px" }}
                          target="_blank"
                        >
                          Artist Instagram
                          <span className="up_sign_faq">↠</span>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Scene>
          </div>
        </div>
        <div className="wrapper-section" style={{ color: "white" }}>
          <div className="socials-container">
            <Scene classToggle={"reveal"} reverse={false} indicators={false}>
              <div className="animate">
                <div className="social-container-objects">
                  <a target="_blank" href="https://discord.gg/ZNycXk8pKa">
                    <img
                      className="social-container-objects-images"
                      src={discordButton}
                      alt="discord_link"
                    />
                  </a>
                </div>
                <div className="social-container-objects">
                  <a target="_blank" href="https://twitter.com/CyberInkNFT">
                    <img
                      className="social-container-objects-images"
                      src={twitterButton}
                      alt="twitter_link"
                    />
                  </a>
                </div>
                <div className="social-container-objects">
                  <a
                    target="_blank"
                    href="https://opensea.io/collection/cyber-viking-warriors"
                  >
                    <img
                      className="social-container-objects-images"
                      src={openseaButton}
                      alt="opensea_link"
                    />
                  </a>
                </div>
              </div>
            </Scene>
          </div>
        </div>

        <div className="text-container-artist"></div>
        <div className="wrapper-section">
          <div className="faq-container clippy2">
            <Scene classToggle={"reveal"} reverse={false} indicators={false}>
              <div className="animate">
                <h1 className="none">CyberInk Faq</h1>
                <img
                  className="faq-header section-header"
                  src={FaqHeader}
                  alt="CyberInk Faq"
                />
                <BlockFaq>
                  <Faq />
                </BlockFaq>
              </div>
            </Scene>
          </div>
        </div>
        <div className="footer-container">
          <Footer />
        </div>
      </Controller>
    </React.Fragment>
  );

  {
    /* <a
              href="https://opensea.io/"
              title="Buy on OpenSea"
              target="_blank"
              className="opensea-embed"
            >
              <img
                src="https://storage.googleapis.com/opensea-static/Logomark/Badge%20-%20Available%20On%20-%20BW.webp"
                alt="Available on OpenSea"
              />
            </a> */
  }
}
