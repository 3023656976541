import styled from "styled-components";

export const Card = styled.div`
  margin: auto;
  width: 330px;
  height: 500px;
  opacity: 0.9;
  margin: 20px;
  box-shadow: 0 30px 45px 15px rgb(15, 15, 15);
  border-radius: 20px;
  display: inline-block;
  background: rgba(122, 120, 120, 0.637);
  color: white;
  font-family: "Roboto";

  @media only screen and (max-width: 600px) {
    .card {
      clear: both;
    }
  }
`;

export const CardImage = styled.img`
  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin-top: 20px;
`;

export const CardText = styled.p`
  margin: 0 auto;
  width: 270px;
`;

export const CardName = styled.p`
  font-size: 2em;
`;

export const Container = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
  top: 25%;

  @media only screen and (max-width: 900px) {
    top: 12%;
  }
`;
