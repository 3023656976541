import React from "react";
import "./Nav.scss";
import { useState } from "react";
import instagram from "./assets/insta.png";
import twitter from "./assets/twitter.png";
import discord from "./assets/discord.png";
import opensea from "./assets/opensea.png";
import logo from "./assets/logo.png";
import faq from "./assets/faq.png";
import utility from "./assets/utility.png";
import roadmap from "./assets/roadmap.png";

import { scrollToSection } from "./../../helpers/scrollTo";

function Nav(props) {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const handleNavigationClick = (selector) => {
    handleClick();
    scrollToSection(selector);
  };

  const handlePopupCallback = (action) => {
    handleClick();
    action();
  };

  return (
    <>
      <div className={click ? "navbar-container active" : "navbar-container"}>
        <nav className="navbar">
          <a
            onClick={() => handleNavigationClick("home")}
            aria-disabled="true"
            style={{ cursor: "pointer" }}
          >
            <img className="logo" src={logo} alt="logo_cyberink" />
          </a>

          <div className="menu-icon" onClick={handleClick}>
            <span style={{ color: "white" }}>{click ? "✖" : "☰"}</span>
          </div>
        </nav>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item link-container utility-li">
            <span
              onClick={() => handleNavigationClick("utility")}
              className="text-menu"
            >
              <img src={utility} alt="Utility" />
            </span>
          </li>

          <li className="nav-item link-container roadmap-li">
            <span
              onClick={() => handleNavigationClick("roadmap")}
              className="text-menu"
            >
              <img src={roadmap} alt="Roadmap" />
            </span>
          </li>

          <li className="nav-item link-container faq-li">
            <span
              onClick={() => handleNavigationClick("faq-container")}
              className="text-menu"
            >
              <img src={faq} alt="Faq" />
            </span>
          </li>
        </ul>
        <div className={click ? "socials active" : "socials"}>
          <a target="_blank" href="https://opensea.io/collection/cyber-viking-warriors">
            <img src={opensea} alt="Opensea" />
          </a>
          <a target="_blank" href="https://discord.gg/ZNycXk8pKa">
            <img src={discord} alt="Discord" />
          </a>
          <a target="_blank" href="https://twitter.com/CyberInkNFT">
            <img src={twitter} alt="Twitter" />
          </a>
          <a target="_blank" href="https://www.instagram.com/cyberinknft">
            <img src={instagram} alt="Instagram" />
          </a>
        </div>
      </div>
    </>
  );
}

export default Nav;
