import { StrictMode } from "react";
import ReactDOM from "react-dom";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Todo from "./pages/Todo";

import LoadingScreenComponent from "./pages/LoadingScreen";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";

import "./styles.scss";
import { BlockchainContextProvider } from "./context/BlockchainContext";

function LoadingScreen() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loader = async () => {
      await setTimeout(function () {
        setIsLoading(false);
      }, 4000);
    };
    loader();
  }, [isLoading]);


  return !isLoading ? (
    
      <StrictMode>
        <Router>
          <Routes>
            <Route index element={<Home />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </Router>
      </StrictMode>
   
  ) : (
    <LoadingScreenComponent />
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(
  <BlockchainContextProvider>
    <LoadingScreen />
  </BlockchainContextProvider>,
  rootElement
);
