import * as React from "react";
import MembersContainer from "./../components/members/MemberContainer";

export default function Team() {
  return (
    <>
      <MembersContainer />
    </>
  );
}
